import React from 'react';
import PropTypes from 'prop-types';
import {
  Asset,
  TextLink,
  Button,
  FormLabel,
  Tag,
} from '@contentful/forma-36-react-components';
import {
  useAsset,
  useCreateAsset,
  useSelectAsset,
} from '../../Context/ContentfulContext';

const AssetPreview = ({ assetId = null }) => {
  const [assetDetails, reloadAsset] = useAsset(assetId);
  if (!assetDetails) return null;
  if (assetDetails.reload) {
    return (
      <>
        <Button icon='Asset' size='small' onClick={reloadAsset}>
          Reload asset
        </Button>
        <br />
      </>
    );
  }
  return <>
    <Asset src={assetDetails.file.url} title={assetDetails.title} />
    <Tag tagType={assetDetails.published ? 'positive' : 'warning'}>
      {assetDetails.published ? 'Published' : 'Draft'}
    </Tag>
  </>;
};

AssetPreview.propTypes = {
  assetId: PropTypes.string
};

const AssetPicker = ({
  assetId,
  setAssetId,
  required = false,
  text = 'Asset',
  showAsset = true,
}) => {
  const createAsset = useCreateAsset();
  const pickAsset = useSelectAsset();
  const unlinkAsset = () => setAssetId(null);
  const linkNewAsset = () => createAsset().then(assetId => setAssetId(assetId));
  const linkExistingAsset = () =>
    pickAsset().then(assetId => setAssetId(assetId));

  return (
    <>
      <FormLabel required={required} htmlFor={text}>
        {text}
      </FormLabel>
      <br />
      {showAsset && <AssetPreview assetId={assetId} />}
      {assetId ? (
        <TextLink onClick={unlinkAsset} icon='Close'>
          Unlink asset
        </TextLink>
      ) : (
        <>
          <TextLink onClick={linkNewAsset} icon='Plus'>
            Create new asset and link
          </TextLink>
          <div style={{ display: 'inline-block', width: 20 }} />
          <TextLink onClick={linkExistingAsset} icon='Link'>
            Link an existing asset
          </TextLink>
        </>
      )}
    </>
  );
};
AssetPicker.propTypes = {
  assetId: PropTypes.string,
  setAssetId: PropTypes.func,
  required: PropTypes.bool,
  text: PropTypes.string,
  showAsset: PropTypes.bool,
};

export default AssetPicker;
