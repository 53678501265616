import React from 'react';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';

// MPC Editor Component
const HarmonicaEditor = () => (
  <AnswerList
    answerOptions={{
      valid: false,
      text: true,
      asset: false,
    }}
  />
);

export default HarmonicaEditor;
