import React, { useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, FormLabel } from '@contentful/forma-36-react-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addCard as addCardAction,
  moveCard as moveCardAction,
  removeCard as removeCardAction,
  setCardValue as setCardValueAction,
} from '../../State/actions';
import QuestionCard from '../QuestionCard/QuestionCard';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 8px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const CardList = ({
  title,
  cardOptions,
  cardLabels,
  maxItems,
}) => {
  const dispatch = useDispatch();
  const answers = useSelector(state => state.answers);
  const items = useSelector(state => state.cards);

  const addCard = () => dispatch(addCardAction());
  const moveCard = (from, to) => dispatch(moveCardAction(from, to));
  const removeCard = index => () => dispatch(removeCardAction(index));
  const setValue = useCallback(index => (field, value) =>
    dispatch(setCardValueAction(index, field, value)), [dispatch])

  cardOptions = { ...CardList.defaultProps.cardOptions, ...cardOptions };
  cardLabels = { ...CardList.defaultProps.cardLabels, ...cardLabels };
  const onDragEnd = result => {
    if (!result.destination) return;
    moveCard(result.source.index, result.destination.index);
  };
  useEffect(() => {
    items.forEach((item, i) => {
      if (item.answers !== answers) {
        setValue(i)('answers', answers);
      }
      if (item.correct === false && answers[0]) {
        setValue(i)('correct', answers[0].id);
      }
    });
  }, [items, answers, setValue]);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ marginBottom: 8, display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {title && (
            <FormLabel required={true} htmlFor={title}>
              {title}
            </FormLabel>
          )}
        </div>
        {(items.length < maxItems || maxItems === 0) && (
          <Button icon='Plus' size='small' onClick={addCard}>
            {cardLabels.button}
          </Button>
        )}
      </div>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    <QuestionCard
                      {...cardOptions}
                      {...item}
                      answers={Object.values(answers)}
                      isDraggable={true}
                      dragHandleProps={provided.dragHandleProps}
                      removeHandler={removeCard(index)}
                      setValue={setValue(index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

CardList.defaultProps = {
  cardOptions: {},
  cardLabels: {
    button: 'Add card',
  },
  title: '',
  minItems: 1,
  maxItems: 0,
};
CardList.propTypes = {
  cardOptions: PropTypes.shape({}),
  cardLabels: PropTypes.shape({
    button: PropTypes.string,
  }),
  title: PropTypes.string,
  minItems: PropTypes.number,
  maxItems: PropTypes.number,
};

export default CardList;
