import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import {
  Tag,
  Tooltip,
  FieldGroup,
  CheckboxField,
} from '@contentful/forma-36-react-components';
import AssetPicker from '../AssetPicker/AssetPicker';
import {
  setHotspotImage,
  setAnswerCoords,
  setAssetType,
} from '../../State/actions';
import { useAsset } from '../../Context/ContentfulContext';
import './hotspot.css';

const useSize = () => {
  const [size, setSize] = useState(null);
  const updateSize = ev =>
    setSize({ x: ev.target.clientWidth, y: ev.target.clientHeight });
  return [size, updateSize];
};

const ImageCheckbox = ({
  name,
  labelText,
  helpText,
  disabled = false,
  checked = false,
  onChange = _ => {_},
}) => {
  return (
    <FieldGroup>
      <CheckboxField
        labelText={labelText}
        helpText={helpText}
        disabled={disabled}
        name={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        labelIsLight={false}
        id={name}
      />
    </FieldGroup>
  );
};

ImageCheckbox.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const SpotWithTip = ({ children, tipContent = '' }) =>
  tipContent === '' ? (
    children
  ) : (
    <Tooltip content={tipContent}>
      <span>{children}</span>
    </Tooltip>
  );

SpotWithTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tipContent: PropTypes.string,
};

const Spot = ({
  answer = '',
  valid = false,
  style = {},
  className = '',
  index,
  onMouseDown = undefined,
  onMouseUp = undefined,
  onTouchStart = undefined,
  onTouchEnd = undefined,
  hasValid = true,
}) => {
  const tipContent = [];
  if (answer !== '') tipContent.push(answer);
  if (hasValid) tipContent.push(valid ? '(valid)' : '(invalid)');
  return (
    <div
      {...{ onMouseDown, onMouseUp, onTouchStart, onTouchEnd }}
      className={'hotspot__spot ' + className}
      style={style}>
      <SpotWithTip tipContent={tipContent.join(' ').trim()}>
        {index}
      </SpotWithTip>
    </div>
  );
};

Spot.propTypes = {
  answer: PropTypes.string,
  valid: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  index: PropTypes.number,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  hasValid: PropTypes.bool,
};

const Figure = ({
  hotspots,
  asset,
  setCoords,
  hasValid = true,
  isImage = false,
}) => {
  const [size, updateSize] = useSize();
  const percToAbs = (x, y) => ({
    x: (size.x * x) / 100,
    y: (size.y * y) / 100,
  });
  const absToPerc = (x, y) => ({
    x: (x / size.x) * 100,
    y: (y / size.y) * 100,
  });
  const onStop = index => (_, data) => {
    const { x, y } = absToPerc(data.x, data.y);
    setCoords(index, x, y);
  };
  const cropParams = ({ height }) => '?fit=fill&w=' + height + '&h=' + height;
  return (
    <figure className='hotspot__figure'>
      {size && (
        <div className='hotspot__spots'>
          {hotspots.map(({ x = 50, y = 50, ...spot }, i) => (
            <Draggable
              defaultPosition={percToAbs(x, y)}
              key={spot.id}
              onStop={onStop(i)}
              bounds='parent'>
              <Spot index={i} {...spot} hasValid={hasValid} />
            </Draggable>
          ))}
        </div>
      )}
      <img
        src={
          asset.file.url + (isImage ? cropParams(asset.file.details.image) : '')
        }
        alt=''
        className='hotspot__image'
        onLoad={updateSize}
      />
    </figure>
  );
};

Figure.propTypes = {
  hotspots: PropTypes.array,
  asset: PropTypes.object,
  setCoords: PropTypes.func,
  hasValid: PropTypes.bool,
  isImage: PropTypes.bool,
};

const Hotspot = ({
  assetId,
  setAssetId,
  hotspots,
  isImage,
  setCoords,
  setImageType,
  hasValid = true,
}) => {
  const [asset, reload] = useAsset(assetId);
  const ForceReload = () => {
    reload();
    return <></>;
  };
  return (
    <>
      <AssetPicker
        required={true}
        assetId={assetId}
        setAssetId={setAssetId}
        text='Asset'
        showAsset={false}
      />
      {asset &&
        (asset.reload ? (
          <ForceReload />
        ) : (
          <>
            <Figure
              hotspots={hotspots}
              asset={asset}
              hasValid={hasValid}
              setCoords={setCoords}
              isImage={isImage}
            />
            <Tag tagType={asset.published ? 'positive' : 'warning'}>
              {asset.published ? 'Published' : 'Draft'}
            </Tag>
          </>
        ))}
      <ImageCheckbox
        labelText="Asset is an image"
        helpText={
          'Selecting this will add a blurred version of the image on the background and force a 1:1 ratio. ' +
          "Enabling this won't work for SVG images."
        }
        name="isImage"
        checked={isImage}
        onChange={setImageType}
      />
    </>
  );
};

Hotspot.propTypes = {
  assetId: PropTypes.string,
  setAssetId: PropTypes.func,
  hotspots: PropTypes.array,
  setCoords: PropTypes.func,
  hasValid: PropTypes.bool,
  isImage: PropTypes.bool,
  setImageType: PropTypes.func,
};

const mapStateToProps = state => ({
  assetId: state.hotspot.image,
  isImage: state.hotspot.isImage,
  hotspots: state.answers,
});

const mapDispatchToProps = dispatch => ({
  setAssetId: assetId => dispatch(setHotspotImage(assetId)),
  setCoords: (index, x, y) => dispatch(setAnswerCoords(index, x, y)),
  setImageType: val => dispatch(setAssetType(val)),
});

const ConnectedHotspot = connect(
  mapStateToProps,
  mapDispatchToProps
)(Hotspot);

export default ConnectedHotspot;
