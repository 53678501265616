import React, { useState } from 'react';
import {
  Card,
  IconButton,
  TextField,
  Tag,
  Dropdown,
  DropdownList,
  DropdownListItem,
} from '@contentful/forma-36-react-components';
import AssetPicker from '../AssetPicker/AssetPicker';

const DragHandle = ({ ...props }) => (
  <div className='Card__DragHandle' {...props} />
);

const ValidityToggle = ({
  valid,
  validLabel,
  invalidLabel,
  toggleValid = undefined,
}) => {
  return (
    <>
      <Tag tagType={valid ? 'positive' : 'negative'}>
        {valid ? validLabel : invalidLabel}
      </Tag>
      {toggleValid && (
        <IconButton
          iconProps={{ icon: valid ? 'ThumbDown' : 'ThumbUp' }}
          buttonType='secondary'
          label={'Set ' + (valid ? invalidLabel : validLabel)}
          onClick={toggleValid}
        />
      )}
    </>
  );
};

const Menu = ({ items = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (items.length === 0) return null;
  const toggle = (
    <IconButton
      iconProps={{ icon: 'MoreHorizontal' }}
      buttonType='secondary'
      label='Open menu'
      onClick={() => setIsOpen(!isOpen)}
    />
  );
  return (
    <Dropdown
      onClose={() => setIsOpen(false)}
      toggleElement={toggle}
      isOpen={isOpen}>
      <DropdownList>
        {items
          .filter(i => !!i)
          .map(({ label, action }, i) => (
            <DropdownListItem key={i} onClick={action}>
              {label}
            </DropdownListItem>
          ))}
      </DropdownList>
    </Dropdown>
  );
};

const AnswerCard = ({
  id,
  answer,
  valid,
  setValue,
  text = '',
  asset = null,
  isDraggable = false,
  remove = null,
  options = {valid: false, text: false, asset: false},
  labels = {valid: '', invalid: '', answer: '', text: '', asset: ''},
  dragHandleProps = {},
}) => (
  <Card className='Card--With-DragHandle'>
    {isDraggable && <DragHandle {...dragHandleProps} />}
    <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
      {options.valid && (
        <>
          <ValidityToggle
            valid={valid}
            validLabel={labels.valid}
            invalidLabel={labels.invalid}
          />
          <Menu
            items={[
              options.valid
                ? {
                    label: 'Set ' + (valid ? labels.invalid : labels.valid),
                    action: () => setValue('valid', !valid),
                  }
                : null,
            ]}
          />
        </>
      )}
      {remove && (
        <IconButton
          iconProps={{ icon: 'Close' }}
          buttonType='secondary'
          label='Delete answer'
          onClick={remove}
        />
      )}
    </div>
    <TextField
      id={`answerText-${id}`}
      name='Answer'
      labelText={labels.answer}
      value={answer}
      onChange={el => setValue('answer', el.target.value)}
      required
    />
    {options.text && (
      <TextField
        textarea
        id={`answerLongText-${id}`}
        name='Text'
        labelText={labels.text}
        value={text}
        onChange={el => setValue('text', el.target.value)}
      />
    )}
    {options.asset && (
      <AssetPicker
        text={labels.asset}
        assetId={asset}
        setAssetId={id => setValue('asset', id)}
      />
    )}
  </Card>
);

export default AnswerCard;
