import React from 'react';
import PropTypes from 'prop-types';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';

// MPC Editor Component
const MPCEditor = ({ mpcMaxCorrect = 0 }) => (
  <AnswerList
    answerLabels={{
      valid: 'correct',
      invalid: 'incorrect',
    }}
    answerOptions={{
      valid: true,
      maxValid: mpcMaxCorrect,
      text: false,
      asset: false,
    }}
  />
);

MPCEditor.propTypes = {
  mpcMaxCorrect: PropTypes.number
};

export default MPCEditor;
