import { combineReducers } from 'redux';

import {
  IMPORT_CMS_STATE,
  MOVE_ANSWER,
  ADD_ANSWER,
  REMOVE_ANSWER,
  MOVE_CARD,
  ADD_CARD,
  REMOVE_CARD,
  SET_CARD_VALUE,
  SET_ANSWER_VALUE,
  SET_HOTSPOT_IMAGE,
  SET_ANSWER_COORDS,
  SET_ASSET_TYPE,
} from './actions';

import {v4 as uuidv4 } from 'uuid';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const remove = (list, index) => {
  const result = Array.from(list);
  result.splice(index, 1);
  return result;
};

const preprocessCMSState = ({
  answers = [],
  cards = [],
  hotspot = {},
  ...state
} = {}) => ({
  ...state,
  cards,
  hotspot,
  answers: answers.map(answer => {
    answer.id = !answer.id ? uuidv4() : answer.id;
    return answer;
  }),
});

const initialState = {
  answers: [],
  hotspot: {
    isImage: false,
  },
  cards: [],
};

const answerTemplate = {
  id: '',
  answer: '',
  asset: null,
  text: '',
  valid: false,
};

const addNewAnswer = (state, values = {}) => [
  ...state,
  { ...answerTemplate, id: uuidv4(), ...values },
];

const setAnswerValue = (state, index, field, value) => {
  const result = [...state];
  result[index][field] = value;
  return result;
};

const setAnswerCoords = (state, index, x, y) => {
  const result = [...state];
  result[index].x = x.toFixed(2);
  result[index].y = y.toFixed(2);
  return result;
};

const answers = (state = [], action) => {
  switch (action.type) {
    case MOVE_ANSWER:
      return reorder(state, action.from, action.to);
    case ADD_ANSWER:
      return addNewAnswer(state, action.values);
    case REMOVE_ANSWER:
      return remove(state, action.index);
    case SET_ANSWER_VALUE:
      return setAnswerValue(state, action.index, action.field, action.value);
    case SET_ANSWER_COORDS:
      return setAnswerCoords(state, action.index, action.x, action.y);
    default:
      return state;
  }
};

const initialHotspot = { isImage: false };
const hotspot = (state = initialHotspot, action) => {
  switch (action.type) {
    case SET_HOTSPOT_IMAGE:
      return { ...state, image: action.assetId };
    case SET_ASSET_TYPE:
      return { ...state, isImage: action.isImage };
    default:
      return state;
  }
};

const cardTemplate = {
  id: '',
  question: '',
  correct: '',
  answers: {},
};

const addNewCard = (state, values = {}) => [
  ...state,
  { ...cardTemplate, id: uuidv4(), ...values },
];

const setCardValue = (state, index, field, value) => {
  const result = [...state];
  result[index][field] = value;
  return result;
};

const cards = (state = [], action) => {
  switch (action.type) {
    case MOVE_CARD:
      return reorder(state, action.from, action.to);
    case ADD_CARD:
      return addNewCard(state, action.values);
    case REMOVE_CARD:
      return remove(state, action.index);
    case SET_CARD_VALUE:
      return setCardValue(state, action.index, action.field, action.value);
    default:
      return state;
  }
};

const reducer = combineReducers({ answers, hotspot, cards });

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_CMS_STATE:
      return action.state ? preprocessCMSState(action.state) : state;
    default:
      return reducer(state, action);
  }
};

export default globalReducer;
