import React, { useState, useEffect, useContext, useCallback } from 'react';

const ContentfulContext = React.createContext(null);

export const useCreateAsset = () => {
  const { space, navigator } = useContext(ContentfulContext);
  return () =>
    space.createAsset({}).then(asset => {
      const id = asset.sys.id;
      return navigator.openAsset(id, { slideIn: true }).then(() => id);
    });
};

export const useSelectAsset = () => {
  const { dialogs } = useContext(ContentfulContext);
  return () => dialogs.selectSingleAsset().then(el => el.sys.id);
};

export const getAsset = ({ field, space }, id) => {
  const locale = field.locale;
  return space
    .getAsset(id)
    .then(el => {
      if (!el.fields || !el.fields.file || !el.fields.file[locale]) {
        return { reload: true, empty: true };
      }
      if (el.fields.file[locale].url) {
        return el;
      }
      if (el.fields.file[locale].upload) {
        space.processAsset(id);
      }
      return { reload: true };
    })
    .then(el =>
      el.reload
        ? el
        : { 
          file: el.fields.file[locale], 
          title: el.fields.title[locale], 
          published: !!el.sys.publishedAt
        }
    );
};

export const useAsset = assetId => {
  const extension = useContext(ContentfulContext);
  const [assetDetails, setAssetDetails] = useState(null);
  const fetchAsset = useCallback(() => 
    getAsset(extension, assetId)
      .then(setAssetDetails), 
    [assetId, extension]
  );
  useEffect(() => {
    if (!assetId) return;
    fetchAsset();
  }, [assetId, fetchAsset]);
  useEffect(() => {
    if (!assetId || !assetDetails) return;
    setTimeout(extension.window.updateHeight, 500);
  }, [extension.window.updateHeight, assetId, assetDetails]);
  if (!assetId) return [null, () => {}];
  return [assetDetails, fetchAsset];
};

export default ContentfulContext;
