// Main entry point
import { init } from 'contentful-ui-extensions-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { isEqual } from 'lodash-es';
import debounce from 'debounce';

import { Provider } from 'react-redux';
import { importCMSState, addAnswer } from './State/actions';
import { isValid, cfValue } from './State/selector';
import createStore from './State/store';

import './index.css';
import ContentfulContext from './Context/ContentfulContext';
import MPCEditor from './Components/Editor/MPCEditor';
import HotspotEditor from './Components/Editor/HotspotEditor';
import ScrollingAnalysisEditor from './Components/Editor/ScrollingAnalysisEditor';
import CardMatchingEditor from './Components/Editor/CardMatchingEditor';
import CarouselEditor from './Components/Editor/CarouselEditor';
import HarmonicaEditor from './Components/Editor/HarmonicaEditor';
import SwipeEditor from './Components/Editor/SwipeEditor';

const store = createStore();

// create html root
const root = document.getElementById('root');

// init contentful API
init(extension => {
  let stateIsValid = false;
  // init iframe resizer
  extension.window.startAutoResizer();

  // dispatch CMS state
  const fieldValue = extension.field.getValue();
  store.dispatch(importCMSState(fieldValue));

  const updateState = debounce(() => {
    const state = store.getState();
    const valid = isValid(state);
    const newValue = cfValue(state);
    if (newValue === undefined) {
      extension.field.removeValue();
    } else {
      extension.field.setValue(cfValue(state));
    }
    if (valid === stateIsValid) return;
    extension.field.setInvalid(!stateIsValid);
    stateIsValid = valid;
  }, 250);

  // bind onChange event
  extension.field.onValueChanged(value => {
    const localState = cfValue(store.getState());
    const remoteState = value;
    if (!isEqual(localState, remoteState)) {
      store.dispatch(importCMSState(value));
    }
  });

  store.subscribe(updateState);
  const { contentType } = extension.parameters.instance;
  let Editor;
  switch (contentType) {
    case 'hotspot':
      Editor = HotspotEditor;
      break;
    case 'scrollinganalysis':
      Editor = ScrollingAnalysisEditor;
      break;
    case 'cardmatching':
      Editor = CardMatchingEditor;
      break;
    case 'carousel':
      Editor = CarouselEditor;
      break;
    case 'harmonica':
      Editor = HarmonicaEditor;
      break;
    case 'swipe':
      Editor = SwipeEditor;
      break;
    case 'mpc':
    default:
      Editor = MPCEditor;
      break;
  }

  // render component
  ReactDOM.render(
    <Provider store={store}>
      <ContentfulContext.Provider value={extension}>
        <Editor
          {...extension.parameters.install}
          {...extension.parameters.instance}
        />
      </ContentfulContext.Provider>
    </Provider>,
    root
  );
});

// fallback when directly accessed
ReactDOM.render(<div>...</div>, root);

/**
 * By default, iframe of the extension is fully reloaded on every save of a source file.
 * If you want to use HMR (hot module reload) instead of full reload, uncomment the following lines
 */
// if (module.hot) {
//   module.hot.accept();
// }
