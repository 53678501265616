export const IMPORT_CMS_STATE = 'IMPORT_CMS_STATE';

export const MOVE_ANSWER = 'MOVE_ANSWER';
export const ADD_ANSWER = 'ADD_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';

export const SET_ANSWER_VALUE = 'SET_ANSWER_VALUE';
export const SET_ANSWER_COORDS = 'SET_ANSWER_COORDS';

export const MOVE_CARD = 'MOVE_CARD';
export const ADD_CARD = 'ADD_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const SET_CARD_VALUE = 'SET_CARD_VALUE';

export const SET_HOTSPOT_IMAGE = 'SET_HOTSPOT_IMAGE';
export const SET_ASSET_TYPE = 'SET_ASSET_TYPE';

export function importCMSState(state) {
  return { type: IMPORT_CMS_STATE, state };
}

export function moveAnswer(from, to) {
  return { type: MOVE_ANSWER, from, to };
}

export function addAnswer(values = {}) {
  return { type: ADD_ANSWER, values };
}

export function removeAnswer(index) {
  return { type: REMOVE_ANSWER, index };
}

export function setAnswerValue(index, field, value) {
  return { type: SET_ANSWER_VALUE, index, field, value };
}

export function setAnswerCoords(index, x, y) {
  return { type: SET_ANSWER_COORDS, index, x, y };
}

export function moveCard(from, to) {
  return { type: MOVE_CARD, from, to };
}

export function addCard(values = {}) {
  return { type: ADD_CARD, values };
}

export function removeCard(index) {
  return { type: REMOVE_CARD, index };
}

export function setCardValue(index, field, value) {
  return { type: SET_CARD_VALUE, index, field, value };
}

export function setHotspotImage(assetId) {
  return { type: SET_HOTSPOT_IMAGE, assetId };
}

export function setAssetType(isImage) {
  return { type: SET_ASSET_TYPE, isImage };
}
