// eslint-disable-next-line no-unused-vars
export function isValid(state) {
  return false;
}

const isCardFilled = card => card || true;
const isAnswerFilled = ({answer = '', asset = null, text = ''}) => (
  answer !== '' || asset !== null || text !== ''
);

function isEmpty(state) {
  const hotspotEmpty = (!state.hotspot.image || state.hotspot.image === '');
  const cardsEmpty = state.cards.filter(isCardFilled).length === 0;
  const answersEmpty = state.answers.filter(isAnswerFilled).length === 0;
  return hotspotEmpty && cardsEmpty && answersEmpty;
}

export function cfValue(state) {
  if (isEmpty(state)) {
    return undefined;
  }
  return { ...state };
}
