import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, FormLabel } from '@contentful/forma-36-react-components';

import {
  moveAnswer,
  setAnswerValue,
  addAnswer,
  removeAnswer,
} from '../../State/actions';
import AnswerCard from '../AnswerCard/AnswerCard';
import ContentfulContext from '../../Context/ContentfulContext';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 8px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const AnswerList = ({
  title,
  items,
  moveAnswer,
  setAnswerValue,
  addAnswer,
  removeAnswer,
  answerOptions,
  answerLabels,
  minItems,
  maxItems,
}) => {
  const { dialogs } = useContext(ContentfulContext);
  // set default options
  answerOptions = {
    ...AnswerList.defaultProps.answerOptions,
    ...answerOptions,
  };
  answerLabels = { ...AnswerList.defaultProps.answerLabels, ...answerLabels };
  const validItems = answerOptions.valid
    ? items.reduce((c, item) => (item.valid ? c + 1 : c), 0)
    : 0;
  const onDragEnd = result => {
    if (!result.destination) return;
    moveAnswer(result.source.index, result.destination.index);
  };
  const _setAnswerValue = (i, field, value) => {
    if (field === 'valid' && value) {
      if (answerOptions.maxValid === 1 && validItems >= 1) {
        items.forEach((item, index) => {
          if (item.valid) {
            setAnswerValue(index)(field, false);
          }
        });
      } else if (
        answerOptions.maxValid > 1 &&
        validItems >= answerOptions.maxValid
      ) {
        dialogs.openAlert({
          title: 'Error setting value',
          message:
            'Maximum number of correct answers reached. Set one or more answers to incorrect en try again',
        });
        return;
      }
    }
    setAnswerValue(i)(field, value);
  };
  useEffect(() => {
    if (items.length < minItems) {
      for (let i = 0; i < minItems - items.length; i++) {
        addAnswer();
      }
    }
  });
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ marginBottom: 8, display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {title && (
            <FormLabel required={true} htmlFor={title}>
              {title}
            </FormLabel>
          )}
        </div>
        {(items.length < maxItems || maxItems === 0) && (
          <Button icon='Plus' size='small' onClick={addAnswer}>
            {answerLabels.button}
          </Button>
        )}
      </div>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    <AnswerCard
                      {...item}
                      options={answerOptions}
                      labels={answerLabels}
                      setValue={(f, v) => _setAnswerValue(index, f, v)}
                      remove={removeAnswer(index)}
                      isDraggable
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

AnswerList.defaultProps = {
  answerOptions: {
    valid: false,
    maxValid: 0,
    text: false,
    asset: false,
  },
  answerLabels: {
    button: 'Add answer',
    answer: 'Answer',
    text: 'Text',
    asset: 'Asset',
    valid: 'valid',
    invalid: 'invalid',
  },
  title: '',
  minItems: 1,
  maxItems: 0,
  items: [],
};
AnswerList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      text: PropTypes.string,
      valid: PropTypes.bool,
      asset: PropTypes.string,
    })
  ),
  answerOptions: PropTypes.shape({
    valid: PropTypes.bool,
    maxValid: PropTypes.number,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    asset: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  answerLabels: PropTypes.shape({
    button: PropTypes.string,
    answer: PropTypes.string,
    text: PropTypes.string,
    asset: PropTypes.string,
    valid: PropTypes.string,
    invalid: PropTypes.string,
  }),
  title: PropTypes.string,
  minItems: PropTypes.number,
  maxItems: PropTypes.number,
  moveAnswer: PropTypes.func,
  setAnswerValue: PropTypes.func,
  addAnswer: PropTypes.func,
  removeAnswer: PropTypes.func,
};

const mapStateToProps = state => ({
  items: state.answers,
});
const mapDispatchToProps = dispatch => ({
  moveAnswer: (from, to) => dispatch(moveAnswer(from, to)),
  setAnswerValue: index => (field, value) =>
    dispatch(setAnswerValue(index, field, value)),
  addAnswer: () => dispatch(addAnswer()),
  removeAnswer: index => () => dispatch(removeAnswer(index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerList);
