import React from 'react';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';

// Scrolling Analysis Editor Component
const ScrollingAnalysisEditor = () => {
  return (
    <AnswerList
      answerLabels={{
        button: 'Add slide',
        answer: 'Title',
        text: 'Subtitle',
      }}
      answerOptions={{
        valid: false,
        text: true,
        asset: true,
      }}
      minItems={2}
      maxItems={5}
    />
  );
};

export default ScrollingAnalysisEditor;
