import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  IconButton,
  FormLabel,
  TextInput,
  Dropdown,
  DropdownList,
  DropdownListItem,
} from '@contentful/forma-36-react-components';

const CardDragHandle = ({ ...props }) => (
  <div className='Card__DragHandle__Dots' {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0V0z' />
      <path d='M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
    </svg>
  </div>
);

const QuestionCard = ({
  title,
  correct,
  question,
  answers,
  isDraggable,
  dragHandleProps,
  removeHandler,
  setValue,
}) => {
  const [isOpen, setOpen] = useState(false);
  const label = useMemo(
    () =>
      Object.values(answers).reduce(
        (a, ans) => (ans.id === correct ? ans.answer : a),
        'Select correct answer'
      ),
    [correct, answers]
  );
  const setCorrect = answer => {
    setValue('correct', answer.id);
    setOpen(false);
  };
  return (
    <Card className='Card--With-DotHandle'>
      {isDraggable && <CardDragHandle {...dragHandleProps} />}
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {title && (
            <FormLabel required={true} htmlFor={title}>
              {title}
            </FormLabel>
          )}
        </div>
        <div>
          {removeHandler && (
            <IconButton
              iconProps={{ icon: 'Close' }}
              buttonType='secondary'
              label="Delete answer"
              onClick={removeHandler}
            />
          )}
        </div>
      </div>
      <TextInput
        style={{ marginBottom: '8px' }}
        value={question}
        onChange={el => setValue('question', el.target.value)}
      />
      <FormLabel htmlFor="correctAnswer">Correct answer</FormLabel>
      <br />
      <Dropdown
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        toggleElement={
          <Button
            size='small'
            buttonType='muted'
            indicateDropdown
            onClick={() => setOpen(!isOpen)}>
            {label}
          </Button>
        }>
        <DropdownList>
          <DropdownListItem isTitle>Select the correct answer</DropdownListItem>
          {answers.map((ans, aIndex) => (
            <DropdownListItem key={aIndex} onClick={() => setCorrect(ans)}>
              {ans.answer}
            </DropdownListItem>
          ))}
        </DropdownList>
      </Dropdown>
    </Card>
  );
};

QuestionCard.defaultProps = {
  title: 'Question',
  question: '',
  answers: [],
  isDraggable: false,
  dragHandleProps: {},
  correct: null,
};
QuestionCard.propTypes = {
  id: PropTypes.string.isRequired,
  correct: PropTypes.string,
  title: PropTypes.string,
  question: PropTypes.string,
  answers: PropTypes.array,
  isDraggable: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  removeHandler: PropTypes.func,
  setValue: PropTypes.func,
  setAnswerCorrect: PropTypes.func,
};

export default QuestionCard;
