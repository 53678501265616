import React from 'react';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';

// MPC Editor Component
const SwipeEditor = () => (
  <AnswerList
    answerLabels={{
      button: 'Add card',
      answer: 'Statement',
    }}
    answerOptions={{
      valid: true,
      text: false,
      asset: false,
    }}
  />
);

export default SwipeEditor;
