import React from 'react';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';
import Hotspot from '../Hotspot/Hotspot';

// Editor Component
const HotspotEditor = () => {
  return (
    <>
      <Hotspot hasValid={false} />
      <AnswerList
        answerOptions={{
          valid: false,
          text: true,
          asset: false,
        }}
      />
    </>
  );
};

export default HotspotEditor;
