import React from 'react';
import '@contentful/forma-36-react-components/dist/styles.css';
import AnswerList from '../AnswerList/AnswerList';
import CardList from '../CardList/CardList';

// MPC Editor Component
const CardMatchingEditor = () => {
  return (
    <>
      <AnswerList minItems={2} maxItems={4} title="Answer Options" />
      <CardList title="Question Cards" />
    </>
  );
};

export default CardMatchingEditor;
